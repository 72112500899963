import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CountryCards from '../CountryCards';
import { useMedia } from 'react-use';
import { Text as RawText } from '../StepCards';
import {HowItWorksTitleContainer as RawTitleContainer,HowItWorksTitle as Title,FlexBlock} from './HowItWorks'
import { media,HomeSection } from 'ui';
import RightArrowSlider from '../../assets/images/RightArrowSlider.png';
import LeftArrowSlider from '../../assets/images/LeftArrowSlider.png';

const CountryCardsContainer = styled.div`
  width:100%;
  ${media.medium`
    flex:3;
 `}
`

export const TitleContainer = styled(RawTitleContainer)`
  display:flex;
  padding-top:0px;
`

export const Text = styled(RawText)`
  margin-top: ${({ theme }) => theme.spacing()};
`

export const ZoomDestinationsTitleContainer = styled.div`
  width:70%;
  ${media.medium`
    width:100%;
 `}
`

export const SliderContainer = styled.div`
  width:30%;
  display:flex;
  justify-content: space-evenly;
  ${media.medium`
    display:none;
 `}
`

const ArrowContainer = styled.div`
  width:40px;
  height:40px;
  display: block;
  background: #FFA24B;
  padding: 10px;
  border-radius: 17px;
  cursor:pointer;
  display:flex;
`

const Arrow = styled.img`
  width:20px;
  height:20px;
`
const ZoomDestinations = ({sortedCountries}) => {
  const isMobileOrTablet = useMedia('(max-width: 1000px)');
  const {t} = useTranslation();
  const [slider,setSlider] = useState(undefined);
  return (
    <HomeSection>
      <FlexBlock>
        <TitleContainer>
          <ZoomDestinationsTitleContainer>
            <Title>
              {t('home.country.title')}
            </Title>
            <Text>
              {t('home.country.description')}
            </Text>
          </ZoomDestinationsTitleContainer>
          <SliderContainer>
            <ArrowContainer onClick={()=> {if (slider){slider.slickPrev()}}}>
              <Arrow src={LeftArrowSlider}>

              </Arrow>
            </ArrowContainer>
            <ArrowContainer onClick={()=> {if (slider){slider.slickNext()}}}>
              <Arrow src={RightArrowSlider}>

              </Arrow>
            </ArrowContainer>
          </SliderContainer>
        </TitleContainer>

        <CountryCardsContainer>
          <CountryCards
            isCarousel={isMobileOrTablet}
            countries={sortedCountries}
            externalArrows
            setSlider={setSlider}
            home
          />
        </CountryCardsContainer>
      </FlexBlock>
    </HomeSection>
  );
};

export default ZoomDestinations;
