import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Buttons, media, SpinnerContainer,HomeSection } from 'ui';
import {SectionContainer } from 'ui/containers';
import { formatSearch } from 'utils';
import { navigate } from '@reach/router';
import UtilsContext from 'contexts/util';
import Spinner from 'components/Spinner';
import { get } from 'lodash';
import styled, { css } from 'styled-components';
import { API_URL } from 'constants';
import { HowItWorksTitleContainer,HowItWorksTitle } from 'components/Home/HowItWorks';


const DescriptionWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  right: 20%;
  left: 20%;
  max-height: 100px;
  text-align: center;
  transition: all 0.6s ease-in-out;
  }
`;

const AccoDescription = styled.div`
  font: normal normal 600 16px/25px Muli;
  letter-spacing: -0.16px;
  color: #fff;
  opacity: 0.8;
  display: block;
  text-align: center;
  margin-bottom: 50px;
`;

const AccoTitle = styled.div`
  color: #fff;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
`;
const AccoContainer = styled.div`
  margin-top: 30px;
  display:Flex;
  flex-wrap:wrap;
`;

const AccommodationCard = styled.div`
  transition: all 150ms ease;
  height: 435px;
  border-radius: 25px;
  overflow: hidden;
  background-image: url("${props => props.imageUrl}");
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  ${props =>
    props.styleImage === 'setimage' &&
    css`
      background-image: linear-gradient(
          230deg,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.5)
        ),
        url(${props.external ? props.imageUrl : `${API_URL}/${props.imageUrl}`});
    `}
  &:hover ${DescriptionWrapper} {
    transform: translateY(-50px);
    transition: all 0.6s ease-in-out;
  }

  &:hover ${Buttons} {
    opacity: 1;
    display: flex;
    margin-top: 8px;
    transform: translateY(-50px);
    transition: all 0.6s ease-in-out;
  }

  ${media.medium`
    ${props =>
      props.variant === 'small' &&
      css`
        height: 208px;
    `}
  `}
  
  
  ${props =>
  props.marginBottom &&
  css`
      margin-bottom:19px;
  `}
`;

const TitleContainer = styled(HowItWorksTitleContainer)`
  width:325px;
   > * {
    margin-top: 0;

    + * {
      margin-top: 0;
    }
  }
`

const AccommodationCardContainer = styled.div`
  width:100%;
  margin-bottom:19px;
  ${media.medium`
    width:auto;
    flex:1;
    margin-bottom:0px;
  `}
  ${props =>
  props.marginRight &&
  css`
      margin-right:16px;
 `}
`

const AccomodationCardsColumnContainer = styled.div`
  width:100%;
  ${media.medium`
    width:auto;
    flex:1;
  `}
  flex:1;
  display:flex;
  flex-direction:column;
`

const Accommodation = ({ accommodations}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

	const { onClose } = useContext(UtilsContext);

  const handleSearch = item => {
    const params = { your_accommodation:  item.id }
    const search = formatSearch(params);
    navigate(`/search?${search}`);
    onClose()
  }

  useEffect(() => {
    if (accommodations) {
      setIsLoading(false);
    }
  }, [accommodations]);

  return (
    <SectionContainer>
      <HomeSection>
        <TitleContainer>
          <HowItWorksTitle>
            {t('home.logement.title1')}
          </HowItWorksTitle>
          <HowItWorksTitle>
            {t('home.logement.title2')}
          </HowItWorksTitle>
        </TitleContainer>
        {isLoading ? (
          <SpinnerContainer>
           <Spinner />
          </SpinnerContainer>
          ) : (
        <AccoContainer>
          { (accommodations.slice(0,2) || []).map((item) => (
              <AccommodationCardContainer marginRight>
                <AccommodationCard
                  styleImage="setimage"
                  imageUrl={get(item.picture, 'imagePath')}
                >
                  <DescriptionWrapper>
                    <AccoTitle>{item.title}</AccoTitle>
                    <AccoDescription>{ t('country.hotelsCount_plural', { count: item.hotelCount }) }</AccoDescription>
                    <Buttons variant="accommodation">
                      <Button
                        article
                        variant="carnet"
                        onClick={e => {
                          e.preventDefault();
                          handleSearch(item);
                        }}
                      >
                        {t('home.logement.button')}
                      </Button>
                    </Buttons>
                  </DescriptionWrapper>
                </AccommodationCard>
              </AccommodationCardContainer>
            ),
          )}
          <AccomodationCardsColumnContainer>
            { (accommodations.slice(2, 4) || []).map((item) => (
              <AccommodationCard
                styleImage="setimage"
                variant="small"
                marginBottom
                imageUrl={get(item.picture, 'imagePath')|| item.picture}
              >
                <DescriptionWrapper variant="small">
                  <AccoTitle>{item.title}</AccoTitle>
                  <AccoDescription>{ t('country.hotelsCount_plural', { count: item.hotelCount }) }</AccoDescription>
                  <Buttons variant="accommodation">
                    <Button
                      article
                      variant="carnet"
                      onClick={e => {
                        e.preventDefault();
                        handleSearch(item);
                      }}
                    >
                      {t('home.logement.button')}
                    </Button>
                  </Buttons>
                </DescriptionWrapper>
              </AccommodationCard>
              ),
            )}
          </AccomodationCardsColumnContainer>

        </AccoContainer>
        )}
      </HomeSection>
    </SectionContainer>
  );
};

export default Accommodation;
