import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import ReadMore from '../ReadMore';

const ContentWrapper = styled.div`
  * {
    font-size:15px !important;
  }
`
const AccreditationContainer = styled.div`
  width:100%;
  display:flex;
  padding-bottom:30px;
  padding-top:30px;
  border-bottom:1px solid #F7F7F7;
`
const ImageContainer = styled.div`
  flex: 0 0 90px;
  height:90px;
  padding:20px;
  background-color: #F7F7F7;
  border-radius:18px;
  display:flex;
  flex-direction:column;
  justify-content:center;
`

const Image = styled.img`
  width:100%;
  height:100%;
`

const TextContainer = styled.div`
  flex:1;
  margin-left: 25px;
`

const Title = styled.p`
  font-weight:bold;
  font-size: 16px;
`

export default function LabelsSection({hotel}) {
  const { t } = useTranslation();

  return (
    <div className="tw py-16 px-8 sm:px-0 mx-8 xl:mx-0">
      <h3 className="text-2xl font-bold text-brand-black">{t('hotel.overview.labels.title')}</h3>
      {hotel.sustainable && hotel.sustainable.accreditations && hotel.sustainable.accreditations.length > 0 ? (
        <>
          {hotel.sustainable.accreditations.map((accreditation) =>
            <AccreditationContainer>
              <ImageContainer>
                <Image src={accreditation.pictogram.url}>
                </Image>
              </ImageContainer>
              <TextContainer>
                <Title>
                  {accreditation.displayName}
                </Title>
                <ContentWrapper>
                  <ReadMore noBreak>{accreditation.content}</ReadMore>
                </ContentWrapper>
              </TextContainer>
            </AccreditationContainer>
          )}
        </>
        ) : (
        <p>{t('hotel.overview.labels.noLabels')}</p>
      )}
    </div>
  )
}
