import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Grid, Cell, media, Button,HomeSection } from 'ui';
import IcPlatform from '../../assets/images/icons/IcPlatform';
import IcHotel from '../../assets/images/icons/IcHotel';
import IcCharity from '../../assets/images/icons/IcCharity';
import { Link } from '@reach/router';
import { TitleContainer, GridContainer } from 'ui/containers';
import accent from 'images/accent.png';
import {QualityCard} from '../../ui/cards';
import PageContext from '../../contexts/page';

const QualityTitle = styled.h3`
  margin-bottom: -4px;
  text-align: center;
  font-size:  1.8em;
  letter-spacing: -0.64px;
  color: #1A1A1A;
  opacity: 1;
  line-height: ${({ theme }) => theme.spacing(3.2)};

  ${media.tablet`
    font-size: 1.8em;
  `};
  ${media.medium`
    font-size: 1.4em;
  `}
  ${media.desktop`
    font-size: 1.8em;
  `}
  ${media.mobile`
    font-size: 1.3em;
    line-height: ${({ theme }) => theme.spacing(2.5)};
  `}

`;


const QualityTitleRespon = styled.span`
  background-image: url('${accent}');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size:contain;
`;

const Quality = () => {
  const {t} = useTranslation();
  const { getLanguagePrefix } = useContext(PageContext);
  return (
    <HomeSection>
      <TitleContainer demarche>
        <QualityTitle>
          {t('home.sustainable.demarche')}{' '}
          <QualityTitleRespon>
            {t('home.sustainable.demarcheEnd')}
          </QualityTitleRespon>
        </QualityTitle>
      </TitleContainer>
      <Grid variant="home">
        <Cell sizes={{ default: 3 / 3, desktop: 3 / 3 }}>
          <GridContainer variant="sustainable">
            <QualityCard
              icon={<IcPlatform size={60} />}
              title={t('home.sustainable.card.titre1')}
              url={getLanguagePrefix(t('url.sustainable'))}
              slug={'commitment'}
            />
            <QualityCard
              icon={<IcHotel size={60} />}
              title={t('home.sustainable.card.titre2')}
              url={getLanguagePrefix(
                `${t('url.sustainable')}/${t(
                  'sustainableDevelopment.partners.slug',
                )}`,
              )}
              slug={'certifications'}
            />
            <QualityCard
              icon={<IcCharity size={60} color="#73C992" />}
              title={t('home.sustainable.card.titre3')}
              url={getLanguagePrefix(
                `${t('url.sustainable')}/${t(
                  'sustainableDevelopment.partners.slug',
                )}`,
              )}
              slug={'partners'}
            />
          </GridContainer>
          <Button
            sustainable
            variant="carnet"
            as={Link}
            to={getLanguagePrefix(t('url.sustainable'))}
          >
            {t('header.feelingoJobPopover.sustainableMethod')}
          </Button>
        </Cell>
      </Grid>
    </HomeSection>
  );
};

export default Quality;
