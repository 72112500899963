import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GridContainer } from 'ui/containers';
import { CountryCard } from 'ui/cards';
import PageContext from 'contexts/page';
import { filter } from 'lodash';
import { useMedia } from 'react-use';
import RightArrowSlider from '../assets/images/RightArrowSlider.png';
import LeftArrowSlider from '../assets/images/LeftArrowSlider.png';
import Slider from 'react-slick';
import { media } from 'ui';
import { useTranslation } from 'react-i18next';
import FlecheCountries from '../assets/images/allCountries.svg';

const Carousel = styled.div`
  width: calc(100% - 36px);
  margin-left: auto;
  margin-right: auto;

  .slick-slider {
    .slick-next,
    .slick-prev {
      width: 63px;
      height: auto;
      top: -48px;
      z-index: 30;
      margin: ${({ theme }) => theme.spacing(-1)};
    }

    .slick-next:before,
    .slick-prev:before {
      content: none;
    }
    
    .slick-next:after {
      content: none;
    }

    .slick-next {
      right: 100px;
    }

    .slick-prev {
      left: 70%;
    }
    ${media.tablet`
        slick-next {
          right: 100px;
        }
        .slick-prev {
          left: 72%;
        }
    `}

     ${media.tabletprod`
        slick-next {
          right: 100px;
        }
        .slick-prev {
          left: 75%;
        }
    `}

    ${media.mobile`
    
      .slick-next,
      .slick-prev {
          width: 45px;
          top: -25px;
        }

      .slick-prev {
        left: 65%;
      }
      
      .slick-next {
        right: 10%;
      }
    `}
  }
`;
export const CountryEndBtnContent = styled.div`
  background: #ffa24b;
  border-radius: 25px;
  padding-top: 30px;
  height: 288px;
  opacity: 1;
  position: relative;
`;

const CountryLink = styled(Link)`
  text-decoration: none;
`;

export const MoreCountryLink = styled(Link)`
  text-decoration: none;
  text-align: center;
  color: #1a1a1a;
  font-size: 18px;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: bold;

  img {
    margin: auto;
  }
`;
const CountryCards = ({ countries, isCarousel, home, externalArrows, setSlider }) => {
  const { getLanguagePrefix } = useContext(PageContext);
  const isMobile = useMedia('(max-width: 750px)');
  const isTablet = useMedia('(min-width: 750px) and (max-width: 1000px)');
  const { t } = useTranslation();

  const slidesToShow =
    isMobile || isTablet
      ? 1
        : Math.min(countries.length,5);

  const slidesToScroll = isMobile || isTablet ? 1 : Math.min(countries.length,5);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: '#FFA24B',
          padding: '10px',
          borderRadius: '17px',
        }}
        onClick={onClick}
      >
        <img src={RightArrowSlider} alt="" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
          display: 'block',
          background: '#FFA24B',
          padding: '10px',
          borderRadius: '17px',
          zIndex: '10',
        }}
        onClick={onClick}
      >
        <img src={LeftArrowSlider} alt="" />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    initialSlide: 0,
    autoplay: false,
    arrows: true,
    nextArrow: !externalArrows ? <SampleNextArrow /> :undefined,
    prevArrow: !externalArrows ? <SamplePrevArrow /> :undefined,
  };

  if (isCarousel) {
    return (
      <Carousel>
        <Slider {...settings} ref={slider => {if (setSlider)setSlider(slider) }} style={{ marginLeft: -16, marginRight: -16 }}>
          {filter(countries || [], ({ pictures }) => pictures.length !== 0).map(
            (country, index) => (
              <CountryLink key={index} to={getLanguagePrefix(country?.slug)}>
                <CountryCard {...country} />
              </CountryLink>
            ),
          )}
          <CountryEndBtnContent>
            <MoreCountryLink
              variant="outline"
              as={Link}
              to={getLanguagePrefix(t('url.countries'))}
            >
              {t('home.country.btncountries')}
              <img src={FlecheCountries} alt="" />
            </MoreCountryLink>
          </CountryEndBtnContent>
        </Slider>
      </Carousel>
    );
  }

  return (
    <GridContainer variant="explore">
      {filter(countries || [], ({ pictures }) => pictures.length !== 0).map(
        (country, index) => (
          <CountryLink key={index} to={getLanguagePrefix(country?.slug)}>
            <CountryCard {...country} />
          </CountryLink>
        ),
      )}
      {home ? (
        <CountryEndBtnContent>
          <MoreCountryLink
            variant="outline"
            as={Link}
            to={getLanguagePrefix(t('url.countries'))}
          >
            {t('home.country.btncountries')}
            <img src={FlecheCountries} alt="" />
          </MoreCountryLink>
        </CountryEndBtnContent>
      ) : (
        <></>
      )}
    </GridContainer>
  );
};

export default CountryCards;
