import React, { useContext,  useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PageContext from '../contexts/page';
import { useAsync } from 'react-async';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { Button, media, HomeSection } from 'ui';
import SearchBar from 'components/Search/SearchBarAccueil';
import { SectionContainer } from 'ui/containers';
import doodleLarge from 'images/doodleLarge.png';
import highlighting from 'images/highlighting.png';
import AuthContext from 'contexts/auth';
import { useTranslation } from 'react-i18next';
import ArticleContent from 'components/Article/ArticleContent';
import HomeTravelogue from '../components/Home/HomeTravelogue';
import { getSelectedHotel } from 'api/hotels';
import { getAccommodations } from 'api/accommodations';
import { useDataLayerBuilder } from '@tymate/react-gtm';
import { orderBy, reverse } from 'lodash';
import TicTacTrip from 'components/TicTacTrip';
import { API_URL } from '../constants';
import Modal from 'components/Modal';
import Accommodation from '../components/Home/Accommodation';
import HowItWorks from '../components/Home/HowItWorks';
import ZoomDestinations from '../components/Home/ZoomDestinations';
import Durability from '../components/Home/Durability';
import Quality from '../components/Home/Quality'
import FeelingoodHomes from '../components/Home/FeelingoodHomes'
import FooterLogo from '../components/FooterLogo';
import Logofeelingo from '../assets/images/Logofeelingo.svg';
import LabelsSection from 'components/Hotel/labels-section';


const Picture = styled.div`
  position: relative;
  min-height: 93vh;
  background-position: center center;
  background-size: cover;
  margin-top: -99px !important;
  margin-bottom: 20px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  ${media.desktop`
  margin-top: -99px !important;
 `}
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  + * {
    margin-top: 0;

    ${media.desktop`
          margin-top: 0px;
    `};
  }
  ${media.mobile`
          margin-top: 15px !important;
          width: 90%;
          margin-left: auto;
          margin-right: auto;
    `};
  ${Button} {
    border-color: #ffffff;
    color: #fff;
  }
`;

const MainTitle = styled.h1`
  color: #000000;
  max-width: 15em;
  margin-bottom: 0;
  ${({ theme }) => theme.fontStyles.h1Ultra};
`;

const SubTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content:center;
  background-image:url('${highlighting}');
  background-repeat: no-repeat;
  background-size:100% 100%;
  margin-top:0px !important;
  padding:15px;
`;

const SubTitleText = styled.h6`
  color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  ${({ theme }) => theme.fontStyles.h1Ultra};
`;

const CenterBlock = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: ${({ theme }) => theme.spacing(10)};
  padding-left: ${({ theme }) => theme.spacing()};
  padding-right: ${({ theme }) => theme.spacing()};
  text-align: center;
  align-items: center;

  > * {
    margin-top: 0;

    + * {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  }
`;

export const DiscoverWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: 4px;
  background-image:url('${doodleLarge}');
`;

const HomeBanner = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  z-index: -100;
  width: 100%;
  height: calc(100% - 80px);
  background-position: center center;
  background-size: cover;
  border-radius: 50px;
  ${({ imageURL }) =>
          Boolean(imageURL) &&
          `
    background-image: url(${imageURL});
  `}

  ${media.mobile`
    height:70%;
  `};
`;

const LoaderContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: #67CB8B 0% 0% no-repeat padding-box;

  > a {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    > img{
      height: 60px;

      ${media.mobile`
       height: 40px;
    `};
    }
  }
`


export const query = graphql`
  query($language: String!) {
    generalInformation(language: { eq: $language }) {
      countries {
        slug
        title
        subtitle
        cities {
          totalCount
        }
        hotels {
          totalCount
        }
        icon {
          url
        }
        pictures(randomize: false, limit: 5) {
          mediumUrl
        }
      }
      picture {
        imagePath
      }
      popupContent
      popupTitle
      popupVisible
    }

    allCountry(
      filter: { visibleOnHomepage: { eq: true }, language: { eq: $language } }
      limit: 5
    ) {
      nodes {
        displayName
        title
        subtitle
        hotels {
          totalCount
        }
        slug
        picture {
          imagePath
          smallImagePath
        }
        pictures(randomize: false, limit: 5) {
          mediumUrl
        }
      }
    }

    allBlog(
      limit: 3
      filter: { language: { eq: $language }, visibleOnHomepage: { eq: true } }
    ) {
      nodes {
        id
        slug
        displayName
        publishedAt
        excerpt
        picture {
          imagePath
          smallImagePath
        }
        thematic {
          displayName
        }
      }
    }
    allHotel(limit: 10, filter: { language: { eq: $language } }) {
      nodes {
        id
        originalId
        displayName
        stars
        slug
        priceLevel
        styles {
          displayName
        }
        sustainable {
          level
        }
        city {
          slug
          title
          country {
            title
            slug
          }
        }
        primaryAttachment {
          smallUrl
          mediumUrl
        }
        secondaryAttachments {
          mediumUrl
        }
      }
    }
  }
`;

const Homepage = ({
                    data: {
                      allBlog: { nodes: articles = [] },
                      allCountry: { nodes: country = [] },
                      generalInformation,
                    },
                    ...props
                  }) => {
  useDataLayerBuilder(() => ({
    pageType: 'homepage',
  }));
  const { language } = useContext(PageContext);
  const { isAuthenticated } = useContext(AuthContext);

  const { data = [] } = useAsync({
    promiseFn: getSelectedHotel,
    language,
  });

  const { data: accommodations } = useAsync({
    promiseFn: getAccommodations,
    language,
  });
  const { picture, popupContent, popupTitle, popupVisible } = generalInformation;
  const { t } = useTranslation();

  const sortedCountries = reverse(
    orderBy(country, ({ hotels }) => hotels?.totalCount),
  ).slice(0, 5);

  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(popupVisible);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 2000)
  }, []);

  return (
    <>
    {isLoading ? (
      <LoaderContainer>
        <FooterLogo/>
      </LoaderContainer>
      ) : (
        <>
        <div className='text-center'>
          <img src={Logofeelingo} height="150px" alt="logo Feelingo"/>
          <ArticleContent content="<p className=''>
            Nous travaillons actuellement à l'amélioration de feelingo.com et au développement de nouveaux outils,
            le site sera donc indisponible pendant plusieurs mois.<br></br>Ce qui est sûr c'est qu'on reviendra encore plus performant
            et avec une offre d'hébergements toujours plus engagés ! A très vite !
          </p>" />
          <iframe src="https://giphy.com/embed/0Y77io02hFSM3nFEYP" width="480" height="404" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
          <p href="https://giphy.com/gifs/movie-cool-sunglasses-0Y77io02hFSM3nFEYP">via GIPHY</p>
        </div>
        
        </>
      // <Layout variant="hero">
      //   <SEO title={t('pageTitle.homepage')} />
      //   <Picture>
      //     <CenterBlock>
      //       <MainTitle>{t('home.banner.mainTitleNew')}</MainTitle>
      //       <SubTitleContainer>
      //         <SubTitleText>{t('home.banner.subTitleNew')}</SubTitleText>
      //       </SubTitleContainer>
      //     </CenterBlock>
      //     <SearchBar />
      //     {Boolean(picture) && (
      //       <HomeBanner imageURL={`${API_URL}/${picture?.imagePath}`} />
      //     )}
      //   </Picture>
      //   {isOpen && (
      //     <Modal isOpen scrollable={true} isPopup variant="auth">
      //       <div className="tw px-10">
      //         <div className="pt-14 pb-2 text-center">
      //           <h1 className="text-xl font-bold">{popupTitle}</h1>
      //         </div>
      //         <div className="pt-10 pb-24">
      //           <Content html={popupContent} />
      //         </div>
      //         <Button full variant="popup"
      //                 onClick={() => {
      //                   setIsOpen(false);
      //                 }}>
      //           OK
      //         </Button>
      //       </div>
      //     </Modal>
      //   )}
      //   <SectionContainer>
      //     <HowItWorks></HowItWorks>
      //   </SectionContainer>
      //   <SectionContainer>
      //     <ZoomDestinations sortedCountries={sortedCountries}></ZoomDestinations>
      //   </SectionContainer>
      //   <Accommodation accommodations={accommodations}/>
      //   <SectionContainer background>
      //     <FeelingoodHomes hotels={data} isAuthenticated={isAuthenticated}></FeelingoodHomes>
      //   </SectionContainer>
      //   <SectionContainer>
      //     <Durability></Durability>
      //   </SectionContainer>
      //   <SectionContainer>
      //     <Quality></Quality>
      //   </SectionContainer>

      //   <SectionContainer>
      //     <HomeSection>
      //       <TicTacTrip notCentered/>
      //     </HomeSection>
      //   </SectionContainer>
      //   <SectionContainer background noMarginBottom>
      //     <HomeSection>
      //       <HomeTravelogue articles={articles} />
      //     </HomeSection>
      //   </SectionContainer>
      // </Layout>
      )}
    </>
  );
};

export default Homepage;
