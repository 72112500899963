import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PageContext from '../../contexts/page';
import Button from '../UI/Button';
import ArticleCards from '../ArticleCards';

const HomeTravelogue = ({ articles }) => {
  const { t } = useTranslation();
  const { getLanguagePrefix } = useContext(PageContext);

  return (
    <div>
      <div className="my-8 flex justify-between items-end">
        <div className="max-w-md">
          <div className="text-4xl font-bold">
            {t('home.blog.title')}
          </div>
          <p className="text-gray-500">{t('home.blog.subtitle')}</p>
        </div>

        <div className="hidden md:block">
          <Button to={getLanguagePrefix(t('url.articles'))}>
            {t('home.blog.button')}
          </Button>
        </div>
      </div>

      <ArticleCards articles={articles} background={'#fff'} variant="home" />

      <div className="my-8 md:hidden flex justify-center">
        <Button to={getLanguagePrefix(t('url.articles'))}>
          {t('home.blog.button')}
        </Button>
      </div>
    </div>
  );
};

export default HomeTravelogue;
