import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HomeSection,Button,Buttons } from 'ui';
import styled from 'styled-components';
import { Link } from '@reach/router';
import HotelCardsCarousel from 'components/HotelCardsCarousel';
import PageContext from '../../contexts/page';
import { HowItWorksTitle, HowItWorksTitleContainer } from './HowItWorks';

const Title = styled(HowItWorksTitle)`
  text-align:center;
`

const TitleContainer = styled(HowItWorksTitleContainer)`
  margin-bottom:30px;
`

const FeelingoodHomes = ({hotels,isAuthenticated}) => {
  const {t} = useTranslation();
  const { getLanguagePrefix } = useContext(PageContext);
  return (
    <HomeSection centered>
      <TitleContainer>
        <Title>
          {t('home.hotel.feelingoSelection')}
        </Title>
      </TitleContainer>
      <HotelCardsCarousel hotels={hotels} isAuthenticated={isAuthenticated} />
      <Buttons hasTopMargin>
        <Button
          hotels
          variant="carnet"
          as={Link}
          to={getLanguagePrefix(t('url.hotels'))}
        >
          {t('home.hotel.button')}
        </Button>
      </Buttons>
    </HomeSection>
  );
};

export default FeelingoodHomes;
