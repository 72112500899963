import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { media,Button,HomeSection } from 'ui';
import { useMedia } from 'react-use';
import { Link } from '@reach/router';
import PageContext from '../../contexts/page';
import Groupe from 'images/Groupe.svg';
import Content from 'components/Content';


const Meaning = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  background: #67cb8b 0% 0% no-repeat padding-box !important;
  color: #ffffff;
  border-radius: 25px !important;
  opacity: 1 !important;
  background-color: rgba(115, 201, 146, 0.12);
  min-height: 491px;
  overflow: hidden;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing()};
  padding-left: ${({ theme }) => theme.spacing()};

  button {
    margin: 24px auto 0 auto;
    height: 52px;
  }

  div {
    max-width: 768px;
    margin: 0 auto;
    font-size: ${({ theme }) => theme.spacing(1.25)};
    line-height: ${({ theme }) => theme.spacing(2)};
    letter-spacing: 0.6px;
  }
  ${props =>
  props.durabilite &&
  css`
          background-image:url('${Groupe}') !important;
          background-position: 50% 0.1% !important;
        `}
`;

const TitleDurability = styled.h3`
  width: 469px !important;
  height: 98px !important;
  color: #FFFFFF;
  text-align: center !important;
  font: normal normal bold 40px/48px Muli !important;
  letter-spacing: -0.64px !important;
  margin-top:-52px !important;
  line-height: ${({ theme }) => theme.spacing(3.2)};

  ${media.desktop`
    display:flex !important;
  `};
  ${media.tablet`
    font-size: 1.8em;
  `};
  ${media.medium`
    font-size: 1.4em;
  `}
  ${media.desktop`
    display:flex !important;
    font-size: 1.8em;
  `}
  ${media.mobile`
    font-size: 1.2em;
    width: 300px !important;
    text-align: center !important;
    line-height: ${({ theme }) => theme.spacing(2.5)} !important;
  `}

`;


const Durability = () => {
  const isMobile = useMedia('(max-width: 750px)');
  const { getLanguagePrefix } = useContext(PageContext);
  const {t} = useTranslation();
  return (
    <HomeSection howItWoks>
      <Meaning durabilite>
        <TitleDurability>
          {t('home.durabilit.title')}
        </TitleDurability>
        <Content
          style={{ fontSize: isMobile ? '16px' : '20px' }}
          markdown={t('home.durabilit.content')}
        />

        <Button
          variant="outlineDurability"
          as={Link}
          to={getLanguagePrefix(t('url.sustainableHotel'))}
        >
          {t('home.durabilit.button')}
        </Button>
      </Meaning>
    </HomeSection>
  );
};

export default Durability;
