import React, { useState } from 'react';
import Content from './Content';
import { useTranslation } from 'react-i18next';

export default function ReadMore({ noBreak, noBreakChar = '.', max = 150, children }) {
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);

  const endAt = (noBreak && children && children.includes(noBreakChar)) ? children.indexOf(noBreakChar) : max;

  return (
    <>
      <Content html={children ? !showMore ? children.slice(0, endAt) + ' ...' : children : ''}/>
      <span onClick={() => setShowMore(!showMore)} style={{cursor: 'pointer'}}>
        {!showMore ? t('utils.readMore') : t('utils.readLess')}
      </span>
    </>
  )
}
